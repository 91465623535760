#auth .centerColumn {
    position: absolute;
    width: 440px;
    min-height: 560px;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
}

#auth #BackgroundContaioner > img {
    min-height: 850px;
    height: 100%;
    max-width: unset;
}

#auth div.RedirectBox {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 8px 28px 40px 28px;
}

#auth div.RedirectBox > h2.subTitle {
    margin-top: -30px;
    margin-right: -18px;
    text-transform: uppercase;
}

#auth div.Footer {
    margin-bottom: 10px;
    width: 440px;
}

#auth div.Footer > img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 176px;
}