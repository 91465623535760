#auth div.field {
    display: block;
    width: 100%;
    padding-top: 0.5rem;
}

#auth div.field input {
    margin: 1px;
    display: block;
    width: 100%;
    border-radius: 0.125rem;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(219 219 219 / var(--tw-border-opacity));
    padding: 0.25rem;
    font-size: 14px;
    --tw-text-opacity: 1;
    color: rgb(99 99 99 / var(--tw-text-opacity));
}

#auth div.field.required > label::before {
    font-family: "Proxima Nova", Arial;
    font-size: 14px;
    --tw-text-opacity: 1;
    color: rgb(166 44 39 / var(--tw-text-opacity));
    content: '* ';
}

#auth div.field > label {
    font-family: "Proxima Nova", Arial;
    font-size: 12px;
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
}

#auth div.field input:focus {
    margin: 0;
    border-radius: 0.125rem;
    border-width: 2px;
    --tw-border-opacity: 1;
    border-color: rgb(112 112 112 / var(--tw-border-opacity));
    outline: 2px solid transparent;
    outline-offset: 2px;
}

#auth div.field.readOnly input, #auth div.field.readOnly div.combobox > div.combobox__control {
    pointer-events: none;
    --tw-bg-opacity: 1;
    background-color: rgb(237 237 237 / var(--tw-bg-opacity));
}

#auth div.field.dirty input, #auth div.field.dirty div.combobox > div.combobox__control {
    --tw-border-opacity: 1;
    border-color: rgb(255 167 63 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(254 245 225 / var(--tw-bg-opacity));
}

#auth div.field div.validation {
    font-size: 14px;
    --tw-text-opacity: 1;
    color: rgb(166 44 39 / var(--tw-text-opacity));
    display: none;
}

#auth div.field.invalid input, #auth div.field.invalid div.combobox > div.combobox__control {
    --tw-border-opacity: 1;
    border-color: rgb(166 44 39 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(255 207 207 / var(--tw-bg-opacity));
}

#auth div.field.invalid div.validation {
    display: block;
}

#auth div.field.invalid div.validation > ul {
    list-style-type: disc;
    padding: 0px 20px; /* Brings the bullets over - they're off in no-mans-land otherwise. */
}

#auth .ag-theme-balham .ag-header {
    background: white;
}

#auth .ag-theme-balham .ag-header-row {
    --tw-text-opacity: 1;
    color: rgb(19 40 145 / var(--tw-text-opacity));
}

#auth .ag-theme-balham .ag-root {
    border-top-width: 0px;
}

#auth .ag-theme-balham.fullscreen {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1000;
}

#auth .ag-cell-wrap-text {   
    white-space: normal;
    line-height: 15px;
    height: auto;    
  }

#auth .draggable {
    --tw-bg-opacity: 1;
    background-color: rgb(247 247 247 / var(--tw-bg-opacity));
    font-size: 12px;
    padding: 2px;
}

#auth .draggable.selected {
    --tw-bg-opacity: 1;
    background-color: rgb(149 205 253 / var(--tw-bg-opacity));
    padding: 0;
}

#auth .draggable:nth-child(even) {
    --tw-bg-opacity: 1;
    background-color: rgb(240 240 240 / var(--tw-bg-opacity));
}

#auth .draggable.selected:nth-child(even) {
    --tw-bg-opacity: 1;
    background-color: rgb(136 200 255 / var(--tw-bg-opacity));
}

#auth .draggable .draggable-border {
    border-width: 1px;
    border-style: dotted;
    border-color: rgb(135 135 135 / var(--tw-border-opacity));
    --tw-border-opacity: 0;
}

#auth .draggable.selected .draggable-border {
    border-color: rgb(99 99 99 / var(--tw-border-opacity));
    --tw-border-opacity: 0;
    padding: 2px;
}

#auth .draggable img {
    display: inline-block;
    height: 17px;
    width: 17px;
    margin-right: 5px;
    transform: rotate(90deg);
}

#auth .draggableCollection h5 {
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    padding: 2px 5px;
}

#auth .droppable:focus .draggable.focused .draggable-border {
    --tw-border-opacity: 1;
}

#auth .droppable {
    position: relative;
}

#auth .droppable:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

#auth .droppable .overlay {
    position: absolute;
    display: none;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1000;
    opacity: 0.5;
}

#auth .droppable.draggedOn .overlay {
    display: block;
    cursor: pointer;
    --tw-bg-opacity: 1;
    background-color: rgb(227 236 247 / var(--tw-bg-opacity));
}

#auth .droppable.badDraggedOn .overlay {
    display: block;
    cursor: not-allowed;
}

.auth-tabs {
    margin-top: 8px;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(214 214 214 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.auth-tabHeaders {
    z-index: 2;
}

.auth-tabHeaders .auth-tabHeader {
    margin: 10px 10px 0px 10px;
    padding: 5px 10px;
    border-left-width: 1px;
    border-top-width: 1px;
    border-right-width: 1px;
    border-color: transparent;
    font-family: "Proxima Nova", Arial;
    font-size: 12px;
    text-transform: uppercase;
    --tw-text-opacity: 1;
    color: rgb(19 40 145 / var(--tw-text-opacity));
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.auth-tabHeaders .auth-tabHeader:not(.active):hover {
    cursor: pointer;
    border-left-width: 1px;
    border-top-width: 1px;
    border-right-width: 1px;
    border-color: rgb(240 240 240 / var(--tw-border-opacity));
    --tw-border-opacity: 1;
}

.auth-tabHeaders .auth-tabHeader:focus:not(.active) {
    border-style: dotted;
    --tw-border-opacity: 1;
    border-color: rgb(214 214 214 / var(--tw-border-opacity));
}

.auth-tabHeaders .auth-tabHeader.active {
    border-left-width: 1px;
    border-top-width: 1px;
    border-right-width: 1px;
    border-color: rgb(214 214 214 / var(--tw-border-opacity));
    --tw-border-opacity: 1;
    --tw-bg-opacity: 1;
    background-color: rgb(247 247 247 / var(--tw-bg-opacity));
}

.auth-tabContent {
    z-index: 1;
    margin-top: -1px;
    padding: 5px;
    border-top-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(214 214 214 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(247 247 247 / var(--tw-bg-opacity));
}

*, ::before, ::after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
}

::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
}

#auth .visible {
    visibility: visible;
}

#auth .\!visible {
    visibility: visible !important;
}

#auth .fixed {
    position: fixed;
}

#auth .absolute {
    position: absolute;
}

#auth .relative {
    position: relative;
}

#auth .left-0 {
    left: 0px;
}

#auth .top-0 {
    top: 0px;
}

#auth .right-0 {
    right: 0px;
}

#auth .z-50 {
    z-index: 50;
}

#auth .z-30 {
    z-index: 30;
}

#auth .z-10 {
    z-index: 10;
}

#auth .z-0 {
    z-index: 0;
}

#auth .z-20 {
    z-index: 20;
}

#auth .col-span-11 {
    grid-column: span 11 / span 11;
}

#auth .col-span-10 {
    grid-column: span 10 / span 10;
}

#auth .col-span-1 {
    grid-column: span 1 / span 1;
}

#auth .float-right {
    float: right;
}

#auth .m-0 {
    margin: 0;
}

#auth .mt-xs {
    margin-top: 4px;
}

#auth .mb-xs {
    margin-bottom: 4px;
}

#auth .ml-xs {
    margin-left: 4px;
}

#auth .mr-sm {
    margin-right: 8px;
}

#auth .mt-sm {
    margin-top: 8px;
}

#auth .mb-sm {
    margin-bottom: 8px;
}

#auth .mt-md {
    margin-top: 16px;
}

#auth .ml-xl {
    margin-left: 48px;
}

#auth .ml-md {
    margin-left: 16px;
}

#auth .mr-xs {
    margin-right: 4px;
}

#auth .ml-sm {
    margin-left: 8px;
}

#auth .block {
    display: block;
}

#auth .inline-block {
    display: inline-block;
}

#auth .flex {
    display: flex;
}

#auth .grid {
    display: grid;
}

#auth .hidden {
    display: none;
}

#auth .h-full {
    height: 100%;
}

#auth .h-screen {
    height: 100vh;
}

#auth .h-3 {
    height: 0.75rem;
}

#auth .min-h-full {
    min-height: 100%;
}

#auth .w-full {
    width: 100%;
}

#auth .w-screen {
    width: 100vw;
}

#auth .w-3 {
    width: 0.75rem;
}

#auth .w-4\/5 {
    width: 80%;
}

#auth .w-3\/5 {
    width: 60%;
}

#auth .min-w-1\/4 {
    min-width: 25%;
}

#auth .flex-none {
    flex: none;
}

#auth .flex-grow {
    flex-grow: 1;
}

#auth .flex-grow-0 {
    flex-grow: 0;
}

#auth .transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#auth .cursor-pointer {
    cursor: pointer;
}

#auth .select-none {
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
}

#auth .grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
}

#auth .grid-cols-21 {
    grid-template-columns: repeat(21, minmax(0, 1fr));
}

#auth .grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

#auth .flex-row {
    flex-direction: row;
}

#auth .flex-col {
    flex-direction: column;
}

#auth .content-center {
    align-content: center;
}

#auth .justify-start {
    justify-content: flex-start;
}

#auth .justify-end {
    justify-content: flex-end;
}

#auth .justify-center {
    justify-content: center;
}

#auth .gap-1 {
    gap: 0.25rem;
}

#auth .gap-8 {
    gap: 2rem;
}

#auth .gap-x-3 {
    -moz-column-gap: 0.75rem;
         column-gap: 0.75rem;
}

#auth .justify-self-start {
    justify-self: start;
}

#auth .overflow-hidden {
    overflow: hidden;
}

#auth .overflow-y-auto {
    overflow-y: auto;
}

#auth .rounded {
    border-radius: 0.25rem;
}

#auth .rounded-sm {
    border-radius: 0.125rem;
}

#auth .rounded-md {
    border-radius: 0.375rem;
}

#auth .border {
    border-width: 1px;
}

#auth .border-t {
    border-top-width: 1px;
}

#auth .border-l {
    border-left-width: 1px;
}

#auth .border-r {
    border-right-width: 1px;
}

#auth .border-solid {
    border-style: solid;
}

#auth .border-mono-8 {
    --tw-border-opacity: 1;
    border-color: rgb(184 184 184 / var(--tw-border-opacity));
}

#auth .border-blue-7 {
    --tw-border-opacity: 1;
    border-color: rgb(19 40 145 / var(--tw-border-opacity));
}

#auth .border-orange-2 {
    --tw-border-opacity: 1;
    border-color: rgb(255 167 63 / var(--tw-border-opacity));
}

#auth .border-red-2 {
    --tw-border-opacity: 1;
    border-color: rgb(166 44 39 / var(--tw-border-opacity));
}

#auth .border-mono-12 {
    --tw-border-opacity: 1;
    border-color: rgb(99 99 99 / var(--tw-border-opacity));
}

#auth .border-mono-5 {
    --tw-border-opacity: 1;
    border-color: rgb(219 219 219 / var(--tw-border-opacity));
}

#auth .border-mono-11 {
    --tw-border-opacity: 1;
    border-color: rgb(112 112 112 / var(--tw-border-opacity));
}

#auth .border-mono-4 {
    --tw-border-opacity: 1;
    border-color: rgb(237 237 237 / var(--tw-border-opacity));
}

#auth .border-red-3 {
    --tw-border-opacity: 1;
    border-color: rgb(189 14 8 / var(--tw-border-opacity));
}

#auth .border-mono-6 {
    --tw-border-opacity: 1;
    border-color: rgb(214 214 214 / var(--tw-border-opacity));
}

#auth .bg-mono-5 {
    --tw-bg-opacity: 1;
    background-color: rgb(219 219 219 / var(--tw-bg-opacity));
}

#auth .bg-blue-2 {
    --tw-bg-opacity: 1;
    background-color: rgb(229 238 247 / var(--tw-bg-opacity));
}

#auth .bg-orange-1 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 245 225 / var(--tw-bg-opacity));
}

#auth .bg-red-1 {
    --tw-bg-opacity: 1;
    background-color: rgb(255 207 207 / var(--tw-bg-opacity));
}

#auth .bg-mono-4 {
    --tw-bg-opacity: 1;
    background-color: rgb(237 237 237 / var(--tw-bg-opacity));
}

#auth .bg-blue-6 {
    --tw-bg-opacity: 1;
    background-color: rgb(45 129 189 / var(--tw-bg-opacity));
}

#auth .bg-blue-7 {
    --tw-bg-opacity: 1;
    background-color: rgb(19 40 145 / var(--tw-bg-opacity));
}

#auth .bg-mono-9 {
    --tw-bg-opacity: 1;
    background-color: rgb(138 146 156 / var(--tw-bg-opacity));
}

#auth .bg-mono-2 {
    --tw-bg-opacity: 1;
    background-color: rgb(247 247 247 / var(--tw-bg-opacity));
}

#auth .bg-mono-13 {
    --tw-bg-opacity: 1;
    background-color: rgb(48 55 64 / var(--tw-bg-opacity));
}

#auth .bg-mono-15 {
    --tw-bg-opacity: 1;
    background-color: rgb(44 51 59 / var(--tw-bg-opacity));
}

#auth .bg-mono-1 {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

#auth .bg-mono-7 {
    --tw-bg-opacity: 1;
    background-color: rgb(186 186 186 / var(--tw-bg-opacity));
}

#auth .bg-mono-6 {
    --tw-bg-opacity: 1;
    background-color: rgb(214 214 214 / var(--tw-bg-opacity));
}

#auth .bg-mono-16 {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

#auth .bg-red-2 {
    --tw-bg-opacity: 1;
    background-color: rgb(166 44 39 / var(--tw-bg-opacity));
}

#auth .p-2 {
    padding: 0.5rem;
}

#auth .p-1 {
    padding: 0.25rem;
}

#auth .p-0 {
    padding: 0px;
}

#auth .p-4 {
    padding: 1rem;
}

#auth .p-5 {
    padding: 1.25rem;
}

#auth .pt-2 {
    padding-top: 0.5rem;
}

#auth .pl-8 {
    padding-left: 2rem;
}

#auth .pr-8 {
    padding-right: 2rem;
}

#auth .pl-1 {
    padding-left: 0.25rem;
}

#auth .pl-4 {
    padding-left: 1rem;
}

#auth .pb-2 {
    padding-bottom: 0.5rem;
}

#auth .pt-0 {
    padding-top: 0px;
}

#auth .pt-4 {
    padding-top: 1rem;
}

#auth .text-left {
    text-align: left;
}

#auth .text-center {
    text-align: center;
}

#auth .font-noto {
    font-family: "Noto Sans", Arial;
}

#auth .font-proxima {
    font-family: "Proxima Nova", Arial;
}

#auth .font-arial {
    font-family: Arial;
}

#auth .text-2 {
    font-size: 14px;
}

#auth .text-3 {
    font-size: 16px;
}

#auth .text-8 {
    font-size: 56px;
}

#auth .text-6 {
    font-size: 28px;
}

#auth .text-1 {
    font-size: 12px;
}

#auth .text-4 {
    font-size: 18px;
}

#auth .text-7 {
    font-size: 30px;
}

#auth .font-bold {
    font-weight: 700;
}

#auth .uppercase {
    text-transform: uppercase;
}

#auth .italic {
    font-style: italic;
}

#auth .leading-tight {
    line-height: 1.25;
}

#auth .leading-none {
    line-height: 1;
}

#auth .text-mono-12 {
    --tw-text-opacity: 1;
    color: rgb(99 99 99 / var(--tw-text-opacity));
}

#auth .text-blue-7 {
    --tw-text-opacity: 1;
    color: rgb(19 40 145 / var(--tw-text-opacity));
}

#auth .text-orange-4 {
    --tw-text-opacity: 1;
    color: rgb(125 96 41 / var(--tw-text-opacity));
}

#auth .text-red-3 {
    --tw-text-opacity: 1;
    color: rgb(189 14 8 / var(--tw-text-opacity));
}

#auth .text-mono-8 {
    --tw-text-opacity: 1;
    color: rgb(184 184 184 / var(--tw-text-opacity));
}

#auth .text-blue-2 {
    --tw-text-opacity: 1;
    color: rgb(229 238 247 / var(--tw-text-opacity));
}

#auth .text-mono-1 {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

#auth .text-mono-9 {
    --tw-text-opacity: 1;
    color: rgb(138 146 156 / var(--tw-text-opacity));
}

#auth .text-blue-1 {
    --tw-text-opacity: 1;
    color: rgb(227 236 247 / var(--tw-text-opacity));
}

#auth .text-blue-3 {
    --tw-text-opacity: 1;
    color: rgb(149 205 253 / var(--tw-text-opacity));
}

#auth .text-mono-16 {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
}

#auth .text-mono-4 {
    --tw-text-opacity: 1;
    color: rgb(237 237 237 / var(--tw-text-opacity));
}

#auth .text-red-1 {
    --tw-text-opacity: 1;
    color: rgb(255 207 207 / var(--tw-text-opacity));
}

#auth .text-mono-11 {
    --tw-text-opacity: 1;
    color: rgb(112 112 112 / var(--tw-text-opacity));
}

#auth .text-red-2 {
    --tw-text-opacity: 1;
    color: rgb(166 44 39 / var(--tw-text-opacity));
}

#auth .text-mono-10 {
    --tw-text-opacity: 1;
    color: rgb(135 135 135 / var(--tw-text-opacity));
}

#auth .text-blue-6 {
    --tw-text-opacity: 1;
    color: rgb(45 129 189 / var(--tw-text-opacity));
}

#auth .underline {
    text-decoration-line: underline;
}

#auth .opacity-50 {
    opacity: 0.5;
}

#auth .opacity-0 {
    opacity: 0;
}

#auth .opacity-25 {
    opacity: 0.25;
}

#auth .outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

#auth .filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

#auth .transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.hardContainer, .hcon {
    position: relative;
}

.hardCenter, .hc {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.headerBold {
    font-weight: bold;
}

/*

    Used by example app, included here so PurgeCSS doesn't eat these classes:
    grid grid-cols-6 col-span-5 bg-mono-4 text-mono-8 text-blue-1 font-proxima text-6
    text-blue-5 font-proxima text-3 mb-md bg-mono-16 grid grid-cols-6 gap-2 col-span-2 col-end-7 text-right p-2

*/

#auth .hover\:text-blue-1:hover {
    --tw-text-opacity: 1;
    color: rgb(227 236 247 / var(--tw-text-opacity));
}

#auth .focus\:border-mono-14:focus {
    --tw-border-opacity: 1;
    border-color: rgb(52 59 67 / var(--tw-border-opacity));
}

#auth .focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
#auth .buttonContent {
    top: 50%;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}

#auth button.disabled {
    cursor: default;
}

#auth button {
    position: relative;
}

#auth button .disabledText {
    display: none;
    border-radius: 0.25rem;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(99 99 99 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(237 237 237 / var(--tw-bg-opacity));
    text-align: left;
    position: absolute;
    top: 30px;
    right: 15px;
    width: 250px;
    padding: 5px;
    z-index: 100;
}

#auth button.disabled:hover .disabledText {
    display: block;
}
#bigProblem {
    background-color: #345c93;
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
}

#auth #bigProblem .main {
    width:90vw;
    position: fixed;
    right: 0;
    height: 100vh;
    padding: 65px 100px;
}

#auth #bigProblem .main > * {
    margin-top: 35px;
}

#auth #bigProblem .main h3 {
    font-size: 48px;
    margin-top: 50px;
}

#auth #bigProblem .main img {
    opacity: .7;
}

#auth #bigProblem .main p {
    font-size: 20px;
}

#auth #bigProblem .links {
    margin-top: 20px;
    text-align: right;
}

#auth #bigProblem .links a {
    font-size: 14px;
    padding: 2px 10px 0 10px;
}
#auth div.fillRemainingContainer {
    transition: height 200ms;
}
#auth .prettyCenteredBox {
    position: absolute;
    margin-top: 8px;
    margin-bottom: 8px;
    height: 100vh;
    min-height: 560px;
    left: 50%;
    transform: translateX(-50%);
}

#auth .prettyCenteredBox.defaultWidth {
    width: 440px;
}

#auth #BackgroundContaioner > img {
    min-height: 850px;
    height: 100%;
    max-width: unset;
}

#auth div.ContentBox {
    position: absolute;
    display: flex;
    height: 100%;
    flex-direction: column;
    top: 50%;
    transform: translateY(-50%);
    padding: 8px 28px 40px 28px;
}

#auth div.ContentBox > h2.subTitle {
    margin-top: -30px;
    margin-right: -18px;
    text-transform: uppercase;
}

#auth div.Footer {
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}

#auth div.Footer > img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 176px;
}
#auth .ag-theme-balham .ag-header {
    background: white;
}

#auth .ag-theme-balham .ag-header-row {
    --tw-text-opacity: 1;
    color: rgb(19 40 145 / var(--tw-text-opacity));
}

#auth .ag-theme-balham .ag-root {
    border-top-width: 0px;
}

#auth .ag-theme-balham.fullscreen {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1000;
}

#auth .ag-cell-wrap-text {   
    white-space: normal;
    line-height: 15px;
    height: auto;    
  }
#auth span.status svg {
    margin-right: 4px;
    margin-top: 6px;
    float: left;
}

#auth span.active {
    --tw-text-opacity: 1;
    color: rgb(54 179 0 / var(--tw-text-opacity));
}

#auth span.disabled {
    --tw-text-opacity: 1;
    color: rgb(189 14 8 / var(--tw-text-opacity));
}

#auth span.active svg {
    fill: currentColor;
    stroke: currentColor;
}

#auth span.disabled svg {
    transform: rotate(135deg);
    stroke: currentColor;
}
.tooltip {
  position: absolute;
  z-index: 50;
  border-radius: 0.125rem;
  padding: 0.5rem;
  background-color: white;
  color: black;
}

.tooltip::before {
  position: absolute;
  height: 0.75rem;
  width: 0.75rem;
  background-color: white;
  content: ' ';
  transform: rotate(45deg);
}

/* Orientations */

.tooltip.top {
  margin-bottom: 8px;
}

.tooltip.top::before {
  bottom: -6px;
}

.tooltip.bottom {
  margin-top: 8px;
}

.tooltip.bottom::before {
  top: -6px;
}

.tooltip.right {
  margin-left: 8px;
}

.tooltip.right::before {
  left: -6px;
}

.tooltip.left {
  margin-right: 8px;
}

.tooltip.left::before {
  right: -6px;
}

/* End Orientations */

#auth div.field {
    display: block;
    width: 100%;
    padding-top: 0.5rem;
}

#auth div.field input {
    margin: 1px;
    display: block;
    width: 100%;
    border-radius: 0.125rem;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(219 219 219 / var(--tw-border-opacity));
    padding: 0.25rem;
    font-size: 14px;
    --tw-text-opacity: 1;
    color: rgb(99 99 99 / var(--tw-text-opacity));
}

#auth div.field.required > label::before {
    font-family: "Proxima Nova", Arial;
    font-size: 14px;
    --tw-text-opacity: 1;
    color: rgb(166 44 39 / var(--tw-text-opacity));
    content: '* ';
}

#auth div.field > label {
    font-family: "Proxima Nova", Arial;
    font-size: 12px;
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
}

#auth div.field input:focus {
    margin: 0;
    border-radius: 0.125rem;
    border-width: 2px;
    --tw-border-opacity: 1;
    border-color: rgb(112 112 112 / var(--tw-border-opacity));
    outline: 2px solid transparent;
    outline-offset: 2px;
}

#auth div.field.readOnly input, #auth div.field.readOnly div.combobox > div.combobox__control {
    pointer-events: none;
    --tw-bg-opacity: 1;
    background-color: rgb(237 237 237 / var(--tw-bg-opacity));
}

#auth div.field.dirty input, #auth div.field.dirty div.combobox > div.combobox__control {
    --tw-border-opacity: 1;
    border-color: rgb(255 167 63 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(254 245 225 / var(--tw-bg-opacity));
}

#auth div.field div.validation {
    font-size: 14px;
    --tw-text-opacity: 1;
    color: rgb(166 44 39 / var(--tw-text-opacity));
    display: none;
}

#auth div.field.invalid input, #auth div.field.invalid div.combobox > div.combobox__control {
    --tw-border-opacity: 1;
    border-color: rgb(166 44 39 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(255 207 207 / var(--tw-bg-opacity));
}

#auth div.field.invalid div.validation {
    display: block;
}

#auth div.field.invalid div.validation > ul {
    list-style-type: disc;
    padding: 0px 20px; /* Brings the bullets over - they're off in no-mans-land otherwise. */
}
#auth div.combobox div.combobox__control {
    margin: 1px;
    min-height: 31px;
    width: 100%;
    cursor: pointer;
    border-radius: 0.125rem;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(219 219 219 / var(--tw-border-opacity));
    padding: 0.25rem;
    font-size: 14px;
    --tw-text-opacity: 1;
    color: rgb(99 99 99 / var(--tw-text-opacity));
}

#auth div.combobox div.combobox__control * {
    margin: 0;
    cursor: pointer;
    padding: 0px;
}

#auth div.combobox div.combobox__control:focus {
    margin: 0;
    border-radius: 0.125rem;
    border-width: 2px;
    --tw-border-opacity: 1;
    border-color: rgb(112 112 112 / var(--tw-border-opacity));
    outline: 2px solid transparent;
    outline-offset: 2px;
}

#auth div.combobox .combobox__option--is-selected {
    --tw-bg-opacity: 1;
    background-color: rgb(45 129 189 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

#auth div.combobox .combobox__option--is-focused {
    --tw-bg-opacity: 1;
    background-color: rgb(19 40 145 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

#auth div.combobox .combobox__menu .new::before {
    content: "Create ";
}

#auth div.combobox .combobox__single-value .new::after {
    content: " (new)";
    --tw-text-opacity: 1;
    color: rgb(222 129 20 / var(--tw-text-opacity));
}

#auth div.combobox .combobox__menu .original::after {
    content: " (original)";
}
#auth .draggable {
    --tw-bg-opacity: 1;
    background-color: rgb(247 247 247 / var(--tw-bg-opacity));
    font-size: 12px;
    padding: 2px
}

#auth .draggable.selected {
    --tw-bg-opacity: 1;
    background-color: rgb(149 205 253 / var(--tw-bg-opacity));
    padding: 0
}

#auth .draggable:nth-child(even) {
    --tw-bg-opacity: 1;
    background-color: rgb(240 240 240 / var(--tw-bg-opacity))
}

#auth .draggable.selected:nth-child(even) {
    --tw-bg-opacity: 1;
    background-color: rgb(136 200 255 / var(--tw-bg-opacity))
}

#auth .draggable .draggable-border {
    border-width: 1px;
    border-style: dotted;
    border-color: rgb(135 135 135 / var(--tw-border-opacity));
    --tw-border-opacity: 0
}

#auth .draggable.selected .draggable-border {
    border-color: rgb(99 99 99 / var(--tw-border-opacity));
    --tw-border-opacity: 0;
    padding: 2px
}

#auth .draggable img {
    display: inline-block;
    height: 17px;
    width: 17px;
    margin-right: 5px;
    transform: rotate(90deg)
}

#auth .draggableCollection h5 {
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    padding: 2px 5px
}

#auth .droppable:focus .draggable.focused .draggable-border {
    --tw-border-opacity: 1
}
#auth .droppable {
    position: relative;
}

#auth .droppable:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

#auth .droppable .overlay {
    position: absolute;
    display: none;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1000;
    opacity: 0.5;
}

#auth .droppable.draggedOn .overlay {
    display: block;
    cursor: pointer;
    --tw-bg-opacity: 1;
    background-color: rgb(227 236 247 / var(--tw-bg-opacity));
}

#auth .droppable.badDraggedOn .overlay {
    display: block;
    cursor: not-allowed;
}
#auth .dragIconsContainer {
    position: relative;
}

#auth .dragIcons {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

#auth .dragIcons .arrow {
    height: 25px;
    width: 25px;
}

#auth .dragIcons img.rightArrow {
    margin-top: 2px;
    transform: scaleX(-1);
}
.auth-tabs {
    margin-top: 8px;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(214 214 214 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.auth-tabHeaders {
    z-index: 2;
}

.auth-tabHeaders .auth-tabHeader {
    margin: 10px 10px 0px 10px;
    padding: 5px 10px;
    border-left-width: 1px;
    border-top-width: 1px;
    border-right-width: 1px;
    border-color: transparent;
    font-family: "Proxima Nova", Arial;
    font-size: 12px;
    text-transform: uppercase;
    --tw-text-opacity: 1;
    color: rgb(19 40 145 / var(--tw-text-opacity));
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.auth-tabHeaders .auth-tabHeader:not(.active):hover {
    cursor: pointer;
    border-left-width: 1px;
    border-top-width: 1px;
    border-right-width: 1px;
    border-color: rgb(240 240 240 / var(--tw-border-opacity));
    --tw-border-opacity: 1;
}

.auth-tabHeaders .auth-tabHeader:focus:not(.active) {
    border-style: dotted;
    --tw-border-opacity: 1;
    border-color: rgb(214 214 214 / var(--tw-border-opacity));
}

.auth-tabHeaders .auth-tabHeader.active {
    border-left-width: 1px;
    border-top-width: 1px;
    border-right-width: 1px;
    border-color: rgb(214 214 214 / var(--tw-border-opacity));
    --tw-border-opacity: 1;
    --tw-bg-opacity: 1;
    background-color: rgb(247 247 247 / var(--tw-bg-opacity));
}

.auth-tabContent {
    z-index: 1;
    margin-top: -1px;
    padding: 5px;
    border-top-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(214 214 214 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(247 247 247 / var(--tw-bg-opacity));
}
#auth div.hiderOfNonEditor {
    position: absolute;
}
#auth div.editor {
    position: fixed;
    right: 0px;
    top: 0px;
    width: 700px;
    height: 100%;
    transform: translateX(100%);
}
#auth div.editor.flyout-enter {
    transform: translateX(100%);
    box-shadow: 0px 0 5px 3px rgba(170, 170, 170, 0.5);
}
#auth div.editor.flyout-enter-active {
    transform: translateX(0%);
}
#auth div.editor.flyout-enter-done {
    transform: translateX(0%);
    box-shadow: 0px 0 5px 3px rgba(170, 170, 170, 0.5);
}
#auth div.editor.flyout-exit {
    transform: translateX(0%);
}
#auth div.editor.flyout-exit-active {
    transform: translateX(100%);
}
#auth div.editor.flyout-exit-done {
    transform: translateX(100%);
}
#auth div.editor.flyout-enter-active,
#auth div.editor.flyout-exit-active {
    transition: transform 300ms;
}
#authModalHolder {
    display: none;
    position: fixed;
    top: 0px;
    z-index: 30;
    height: 100%;
    width: 100%;
}

#authModalHolder.active {
    display: block;
}

#authModalBackground {
    position: absolute;
    height: 100%;
    width: 100%;
    --tw-bg-opacity: 1;
    background-color: rgb(138 146 156 / var(--tw-bg-opacity));
    opacity: 0.5;
}

#authModal {
    position: fixed;
    z-index: 10;
    border-radius: 0.25rem;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(184 184 184 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(247 247 247 / var(--tw-bg-opacity));
    padding: 1rem;
    min-width: 200px;
    min-height: 50px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

#authModal .authModalActions {
    display: flex;
    padding-top: 0.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

#authModal .authModalActions button {
    flex: 1 1 0%;
}
#auth div.termsOfUse {
    height: 100%;
    flex-grow: 1;
    line-height: 1.25;
}

#auth div.termsOfUse > * {
    margin: 10px 5px;
}

#auth div.termsOfUse li {
    margin: 10px 5px;
}

#auth div.termsOfUse ul {
    padding-left: 20px;
    list-style: disc;
}

#auth div.termsOfUse ul ul {
    list-style: circle;
}

#auth div.termsOfUse ul ul ul {
    list-style: square;
}