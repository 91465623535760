.wrapper[data-auth-okta] {
  @apply fixed w-screen h-screen left-0 top-0;
}

.wrapper.disabled[data-auth-okta]  {
  @apply hidden;
}

.wrapper[data-auth-okta] .alert {
  @apply fixed top-0 rounded-b bg-white;
  left: calc(50% - 175px);
  padding: 18px 20px 8px 20px;
  width: 350px;
  border-bottom-width: 10px;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.3);
}

.wrapper[data-auth-okta] .overlay {
  @apply opacity-50 fixed w-full h-full bg-brand-black select-none;
}

.wrapper[data-auth-okta] .header {
  @apply flex leading-none;
}

.wrapper[data-auth-okta] .header .icon {
  padding-right: 10px;
}

.wrapper[data-auth-okta] .header .icon i {
  font-size: 35px;
}

.wrapper[data-auth-okta] .header .content {
  @apply flex flex-col flex-grow;
}

.wrapper[data-auth-okta] .header .content .title {
  @apply text-alert-text font-bold;
  font-size: 16px;
}

.wrapper[data-auth-okta] .header .content .description {
  @apply text-alert-text leading-normal;
  font-size: 14px;
}

.wrapper[data-auth-okta] .header .close {
  @apply relative cursor-pointer;
  top: -3px;
}

.wrapper[data-auth-okta] .header .close i {
  font-size: 20px;
}

.wrapper[data-auth-okta] .actions {
  @apply flex;
  padding-top: 10px;
}

.wrapper[data-auth-okta] .actions .button {
  @apply border rounded-sm border-alert-blue text-alert-blue uppercase py-1 px-3 cursor-pointer;
  font-size: 12px;
}

.wrapper[data-auth-okta] .errors {
  font-size: 12px;
  padding-top: 8px;
  padding-left: 62px;
}

.wrapper[data-auth-okta] .errors ul {
  @apply list-disc;
}

.wrapper[data-auth-okta] .progress {
  @apply absolute rounded-b bg-black left-0;
  opacity: 0.1;
  bottom: -10px;
  height: 10px;
}

.wrapper[data-auth-okta] .grow {
  @apply flex-grow;
}

/* Colors */
.wrapper[data-auth-okta] .alert.normal {
  @apply border-alert-light-blue;
}

.wrapper[data-auth-okta] .alert.warning {
  @apply border-alert-warning;
}

.wrapper[data-auth-okta] .alert.success {
  @apply border-alert-success;
}

.wrapper[data-auth-okta] .alert.error {
  @apply border-alert-error;
}

.wrapper[data-auth-okta] .alert.normal .icon {
  @apply text-alert-blue;
}

.wrapper[data-auth-okta] .alert.warning .icon {
  @apply text-alert-warning;
}

.wrapper[data-auth-okta] .alert.success .icon {
  @apply text-alert-success;
}

.wrapper[data-auth-okta] .alert.error .icon {
  @apply text-alert-error;
}

.wrapper[data-auth-okta] .alert .button.warning {
  @apply border-alert-warning text-alert-warning;
}

.wrapper[data-auth-okta] .alert .button.error {
  @apply border-alert-error text-alert-error;
}

.wrapper[data-auth-okta] .alert .button.success {
  @apply border-alert-success text-alert-success;
}
/* End Colors */