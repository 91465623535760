#auth .ag-theme-balham .ag-header {
    background: white;
}

#auth .ag-theme-balham .ag-header-row {
    @apply text-blue-7;
}

#auth .ag-theme-balham .ag-root {
    border-top-width: 0px;
}

#auth .ag-theme-balham.fullscreen {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1000;
}

#auth .ag-cell-wrap-text {
    white-space: normal;
    line-height: 15px;
    height: auto;
    word-wrap: normal;
  }
