#authModalHolder {
    display: none;
    @apply fixed w-full h-full z-30 top-0;
}

#authModalHolder.active {
    display: block;
}

#authModalBackground {
    @apply w-full h-full opacity-50 bg-mono-9 absolute;
}

#authModal {
    @apply fixed z-10 bg-mono-2 border border-mono-8 p-4 rounded;
    min-width: 200px;
    min-height: 50px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

}

#authModal .authModalActions {
    @apply pt-2 pl-8 pr-8 flex;
}
#authModal .authModalActions button {
    @apply flex-1;
}